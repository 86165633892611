import React from "react";
import Hero from "./common/hero";
import Card from "./common/card";
import styles from "../Styles/listingPage.module.css";

type props = {
	data: any[];
};

function ListingPage(props: props) {
	return (
		<>
			{props.data.length !== 0 ? (
				<>
					<Hero data={props.data[0]} />
					<div className={styles.container}>
						{props.data.map((val, index) => {
							return (
								<Card
									key={index}
									style={{ marginLeft: 0, width: "100%", cursor: "pointer" }}
									data={val}
								/>
							);
						})}
					</div>
				</>
			) : (
				<div
					style={{
						height: "calc(100vh - 60px)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					No Blog Found
				</div>
			)}
		</>
	);
}

export default ListingPage;
