import axios from "axios";
import React, { useEffect, useState } from "react";
import ListingPage from "../../Components/ListingPage";
import { sortIt } from "../../utils/utils";

const Cats = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios({
      method: "get",
      url: "https://backend.theholidayfeed.com/api/pets/?animal=cat",
    })
      .then((res) => {
        setData(sortIt(res.data.pets));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <div
      style={{
        height: "calc(100vh - 70px)",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="loader_search"></span>
    </div>
  ) : data.length === 0 ? (
    <div
      style={{
        height: "calc(100vh - 70px)",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      No Blog Found
    </div>
  ) : (
    <ListingPage data={data} />
  );
};

export default Cats;
