import React from "react";
import { ScrollRestoration } from "react-router-dom";
import Hero from "../../Components/common/hero";
import styles from "../../Styles/blogDetails.module.css";

const PrivacyAndPolicy = () => {
	return (
		<div>
			<ScrollRestoration />
			<Hero type="two" data={{ title: "Privacy & Policy" }} />
			<div style={{ background: "#F7D08A" }}>
				<div className={styles.content_container} style={{ padding: "20px" }}>
					<br />
					<div>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
						exercitationem quod animi officia odio dolore repudiandae tempora
						sed rerum natus ducimus quibusdam eaque repellendus, doloremque
						autem libero fugiat voluptatibus optio?
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrivacyAndPolicy;
