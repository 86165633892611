import styles from "../../Styles/appLayout.module.css";
import { ReactComponent as Logo } from "../../Assets/logo/logo.svg";
import { ReactComponent as LogoText } from "../../Assets/logo/logo_text.svg";
import { ReactComponent as Facebook } from "../../Assets/facebook.svg";
import { ReactComponent as Insta } from "../../Assets/insta.svg";
import { ReactComponent as Twitter } from "../../Assets/twitter.svg";
import { ReactComponent as Email } from "../../Assets/icon/sms.svg";
import { ReactComponent as Skype } from "../../Assets/icon/Skype - Negative.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.footer_container}>
      <div className={styles.logo_txt_container}>
        <a
          href="/"
          className={styles.footer_logo}
          style={{ cursor: "pointer" }}
        >
          <Logo />
          <LogoText />
        </a>
        <div style={{ marginTop: "10px" }}>
          <p
            style={{
              color: "rgba(56, 24, 40,.75)",
              marginTop: "25px",
              marginBottom: "10px",
            }}
          >
            {/* about one line comes here */}
          </p>
          <a
            href="mailto:support@thedigitalmediafeed.com"
            style={{
              textDecoration: "none",
              color: "rgba(56, 24, 40,.75)",
            }}
            className={styles.footer_s_link}
          >
            <Email />
            support@thedigitalmediafeed.com
          </a>
          <p
            style={{
              textDecoration: "none",
              color: "rgba(56, 24, 40,.75)",
            }}
            className={styles.footer_s_link}
          >
            <Skype />
            thedigitalmediafeed@gmail.com
          </p>
        </div>
      </div>

      <div className={styles.f_blog_container}>
        <p
          style={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "40px",
            color: "#381828",
          }}
        >
          Category
        </p>
        <ul className={styles.blog_list}>
          <li
            onClick={() => {
              navigate("/dogs");
            }}
            style={{ color: "rgba(56, 24, 40,.75)" }}
          >
            Dogs
          </li>
          <li
            onClick={() => {
              navigate("/cats");
            }}
            style={{ color: "rgba(56, 24, 40,.75)" }}
          >
            Cats
          </li>
          <li
            onClick={() => {
              navigate("/fish");
            }}
            style={{ color: "rgba(56, 24, 40,.75)" }}
          >
            Fish
          </li>
        </ul>
      </div>
      <div className={styles.f_support_container}>
        <p
          style={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "40px",
            color: "#381828",
          }}
        >
          About
        </p>
        <ul className={styles.support_list}>
          <li style={{ color: "rgba(56, 24, 40,.75)" }}>
            <a
              style={{ color: "rgba(56, 24, 40,.75)", textDecoration: "none" }}
              onClick={() => navigate("about-us")}
            >
              About Us
            </a>
          </li>
          <li style={{ color: "rgba(56, 24, 40,.75)" }}>
            <a
              style={{ color: "rgba(56, 24, 40,.75)", textDecoration: "none" }}
              href="https://www.thedigitalmediafeed.com/policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>

          <li style={{ color: "rgba(56, 24, 40,.75)" }}>
            <a
              style={{ color: "rgba(56, 24, 40,.75)", textDecoration: "none" }}
              href="https://www.thedigitalmediafeed.com/terms"
              target="_blank"
            >
              Publisher's Terms
            </a>
          </li>
          <li style={{ color: "rgba(56, 24, 40,.75)" }}>
            <a
              style={{ color: "rgba(56, 24, 40,.75)", textDecoration: "none" }}
              onClick={() => navigate("/terms-and-conditions")}
            >
              Terms & Conditions
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.social__} style={{ alignItems: "flex-end" }}>
        <div
          style={{
            width: "fit-content",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.social_container}>
            <div className={styles.social}>
              <h5>Platform By:</h5>
              <p>
                <a
                  href="https://www.thedigitalmediafeed.com"
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  The Digital Media Feed
                </a>
              </p>
            </div>
            {/* <div className={styles.social}>
							<Facebook />
						</div>
						<div className={styles.social}>
							<Insta />
						</div>
						<div className={styles.social}>
							<Twitter />
						</div> */}
          </div>

          <div className={styles.cw_1}>
            <p>©the pets feed</p>
          </div>
        </div>
      </div>
      <div className={styles.copyright_text}>
        <p>©the pets feed</p>
      </div>
    </div>
  );
};

export default Footer;
