import React from "react";
import styles from "../../Styles/homehero.module.css";
import imggg from "../../Assets/Rectangle 6.svg";
import { useNavigate } from "react-router";
import { reduceText } from "../../utils/reduceText";
import checkForImageType from "../../utils/checkForImageType";

const Homehero = (data: any) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.hero_main}>
        <div className={styles.text_area}>
          <div className={styles.text_area_inside}>
            <h3>{data.data.title}</h3>
            <p>
              {window.innerWidth < 700
                ? reduceText(data.data.shortDescription, 60)
                : data.data.shortDescription}
            </p>
            <div
              className={styles.btn}
              onClick={() => {
                navigate(`/blog/${data.data.id}`);
              }}
            >
              <button>Read More</button>
            </div>
          </div>
        </div>
        <div className={styles.image_area}>
          <img src={checkForImageType(data.data.images[0])} alt="..." />
        </div>
      </div>
    </div>
  );
};

export default Homehero;
