import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

type Props = {
	children?: React.ReactNode;
};

type State = {};

class AppLayout extends React.Component<Props, State> {
	render() {
		return (
			<div style={{ width: "100vw", overflowX: "hidden" }}>
				<Nav />
				<Outlet />
				<Footer />
			</div>
		);
	}
}

export default AppLayout;
