export const reduceChar = (txt: string, upto: number) => {
  if (txt.length < upto) {
    return txt.slice(0, upto);
  }
  return txt;
};

export const sortIt = (arr: any) => {
  return arr.sort((a: any, b: any) => {
    return b.priority - a.priority;
  });
};
