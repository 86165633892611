import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppLayout from "./AppLayout";
import Homepage from "./Pages/Homepage";
import Latest from "./Pages/Latest";
import BlogDetails from "./Pages/BlogDetails";
import Cats from "./Pages/Cats";
import Dogs from "./Pages/Dogs";
import Fish from "./Pages/Fish";
import ContactUs from "./Pages/ContactUs";
import TermsAndConditions from "./Pages/Terms&Conditions";
import PrivacyAndPolicy from "./Pages/PrivacyPolicy";
import AboutUs from "./Pages/AboutUs";
import Animals from "./Pages/Animals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <h1>Error Occured</h1>,
    children: [
      { index: true, element: <Homepage /> },
      { path: "/latest", element: <Latest /> },
      { path: "/cats", element: <Cats /> },
      { path: "/dogs", element: <Dogs /> },
      { path: "/fish", element: <Fish /> },
      { path: "/blog/:id", element: <BlogDetails /> },
      { path: "/privacy-policy", element: <PrivacyAndPolicy /> },
      { path: "/terms-and-conditions", element: <TermsAndConditions /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/trending", element: <Latest /> },
      { path: "/featured", element: <Latest /> },
      { path: "/animal/:id", element: <Animals /> },
    ],
  },
]);

root.render(
  <>
    <RouterProvider router={router} />
  </>
);
