import React, { useEffect, useState } from "react";
import Hero from "../../Components/common/hero";
import Card from "../../Components/common/card";
import axios from "axios";
import ListingPage from "../../Components/ListingPage";
import { ScrollRestoration, useLocation } from "react-router-dom";
import { sortIt } from "../../utils/utils";

const Latest = () => {
  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    axios({
      method: "get",
      url: "https://backend.theholidayfeed.com/api/pets/",
    })
      .then((res) => {
        if (location.pathname === "/trending") {
          setData(
            sortIt(
              res.data.pets.filter((item: any) => item.blogType === "trending")
            )
          );
        } else if (location.pathname === "/featured") {
          setData(
            sortIt(
              res.data.pets.filter((item: any) => item.blogType === "featured")
            )
          );
        } else {
          setData(sortIt(res.data.pets));
        }
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <ScrollRestoration />
      <ListingPage data={data} />
    </>
  );
};

export default Latest;
