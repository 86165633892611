import React from "react";
import styles from "../../Styles/greensection.module.css";
import { useNavigate } from "react-router-dom";
import { reduceText } from "../../utils/reduceText";
import checkForImageType from "../../utils/checkForImageType";

const GreenSection = ({ imageleft, data, ads_data }: any) => {
  const navigate = useNavigate();

  return (
    <>
      {imageleft ? (
        <div className={styles.page_main}>
          <div
            className={styles.image_component}
            style={{
              background: `url('${checkForImageType(data.images[0])}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className={styles.text_area}>
              <div className={styles.text_area_inside}>
                <h3 style={{ color: "#fff" }}>{data.title}</h3>
                <p>
                  {window.innerWidth < 700
                    ? reduceText(data.shortDescription, 170)
                    : data.shortDescription}
                </p>
                <button
                  style={{
                    margin: "20px auto 0 auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "30px",
                    width: "100px",
                    borderRadius: "20px",
                    border: "1px solid white",
                    color: "white",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`blog/${data._id}`);
                  }}
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
          <div className={styles.ad_container}>
            <p>Advertisement</p>
            <div className={styles.ads}>
              <div
                className={styles.ad_1}
                style={{
                  // @ts-ignore
                  background: `url('${checkForImageType(
                    ads_data[0]?.couponImage
                  )}')`,
                }}
              >
                {ads_data[0] && (
                  <>
                    {/* @ts-ignore */}
                    <a href={ads_data[0]?.clickUrl} target="_blank"></a>
                    <div className={styles.background_gradient_for_ads}></div>
                    {/* @ts-ignore */}
                    <p>{ads_data[0]?.title}</p>
                  </>
                )}
              </div>
              <div
                className={styles.ad_2}
                style={{
                  // @ts-ignore
                  background: `url('${checkForImageType(
                    ads_data[1]?.couponImage
                  )}')`,
                }}
              >
                {ads_data[1] && (
                  <>
                    {/* @ts-ignore */}
                    <a href={ads_data[1]?.clickUrl} target="_blank"></a>
                    <div className={styles.background_gradient_for_ads}></div>
                    {/* @ts-ignore */}
                    <p>{ads_data[1]?.title}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.page_main_section2} style={{ color: "white" }}>
          <div className={styles.ad_container}>
            <p>Advertisement</p>
            <div className={styles.ads}>
              <div
                className={styles.ad_1}
                style={{
                  // @ts-ignore
                  background: `url('${checkForImageType(
                    ads_data[2]?.couponImage
                  )}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  // height: !ads_data[2] ? "0" : "",
                }}
              >
                {ads_data[2] && (
                  <>
                    {/* @ts-ignore */}
                    <a href={ads_data[2]?.clickUrl} target="_blank"></a>
                    <div className={styles.background_gradient_for_ads}></div>
                    {/* @ts-ignore */}
                    <p>{ads_data[2]?.title}</p>
                  </>
                )}
              </div>
              {ads_data[3] && (
                <div
                  className={styles.ad_2}
                  style={{
                    // @ts-ignore
                    background: `url('${checkForImageType(
                      ads_data[3]?.couponImage
                    )}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    // aspectRatio: "16/9",
                  }}
                >
                  <>
                    {/* @ts-ignore */}
                    <a href={ads_data[3]?.clickUrl} target="_blank"></a>
                    <div className={styles.background_gradient_for_ads}></div>
                    {/* @ts-ignore */}
                    <p>{ads_data[3]?.title}</p>
                  </>
                </div>
              )}
            </div>
          </div>
          <div
            className={styles.image_component_section2}
            style={{
              background: `url('${checkForImageType(data.images[0])}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className={styles.text_area_section2}>
              <div className={styles.text_area_inside_section2}>
                <h3>{data.title}</h3>
                <p>
                  {window.innerWidth < 700
                    ? reduceText(data.shortDescription, 170)
                    : data.shortDescription}
                </p>
                <button
                  style={{
                    margin: "20px auto 0 auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "30px",
                    width: "100px",
                    borderRadius: "20px",
                    border: "1px solid white",
                    color: "white",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`blog/${data._id}`);
                  }}
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GreenSection;
