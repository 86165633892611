import React from "react";
import styles from "../../Styles/card.module.css";
import { useNavigate } from "react-router-dom";
import checkForImageType from "../../utils/checkForImageType";

type props = {
  style?: any;
  data: any;
};

const Card = (props: props) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.card_container}
      onClick={() => {
        navigate(`/blog/${props.data._id}`);
      }}
      style={{
        ...props?.style,
      }}
    >
      <img src={checkForImageType(props.data.images[0])} alt="" />
      <div className={styles.card_content}>
        <p>{props.data.title}</p>
        <p style={{ marginTop: "30px" }}>Read more</p>
      </div>
    </div>
  );
};

export default Card;
