import React, { useEffect, useState } from "react";
import Hero from "../../Components/common/hero";
import { ScrollRestoration, useParams } from "react-router-dom";
import axios from "axios";
import styles from "../../Styles/blogDetails.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import removeConsecutivePTagsWithBr from "../../utils/removeTags";
import checkForImageType from "../../utils/checkForImageType";

const BlogDetails = () => {
  const param = useParams();
  const [data, setData]: [any, any] = useState(null);
  const [ad, setAd] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      url: `https://backend.theholidayfeed.com/api/pets/${param.id}`,
    }).then((res) => {
      setAd([]);
      setData(res.data.pet);
      let temp_ads = res.data.pet.ads;

      for (let i = 0; i < temp_ads.length; i++) {
        axios({
          url: `https://backend.theholidayfeed.com/api/advertisements/${temp_ads[i]}`,
          method: "get",
        }).then((res: any) => {
          // @ts-ignore
          setAd((prev: any) => {
            return [...prev, res.data.advertisement];
          });
        });
      }
    });
  }, [param.id]);

  console.log(data?.description.replace(/&nbsp;/g, " "));

  return (
    <>
      {data && (
        <div style={{ paddingBottom: "20px" }}>
          <ScrollRestoration />
          <Hero data={data} style={{ height: "75vh" }} />
          {ad.length > 0 && (
            <div className={styles.mob_ad_1}>
              <img
                // @ts-ignore
                src={checkForImageType(ad[0].couponImage)}
                alt=""
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "auto",
                }}
              />
              <h4
                style={{
                  fontSize: "1.2rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[0].title}
              </h4>
              <p
                style={{
                  fontSize: "1rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[0].brand}
              </p>
              {/* @ts-ignore */}
              <a href={ad[0]?.clickUrl}></a>
            </div>
          )}
          <div className={styles.mainContainer}>
            <div
              className={styles.content_container}
              style={{
                padding: " 0 20px 0 0",
              }}
            >
              <div
                className={styles.content_quill}
                dangerouslySetInnerHTML={{
                  __html: data?.description.replace(/&nbsp;/g, " "),
                }}
              ></div>
            </div>

            <div className={styles.ad_container}>
              {ad.length === 0 ? (
                <></>
              ) : (
                ad.map((val: any, index: any) => {
                  return (
                    <a
                      href={val.clickUrl}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "fit-content",
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={checkForImageType(val.couponImage)}
                          alt=""
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "auto",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "1.2rem",
                            textDecoration: "none !important",
                            color: "black",
                          }}
                        >
                          {val.title}
                        </h4>
                        <p
                          style={{
                            fontSize: "1rem",
                            textDecoration: "none !important",
                            color: "black",
                          }}
                        >
                          {val.brand}
                        </p>
                      </div>
                    </a>
                  );
                })
              )}
            </div>
          </div>
          {ad.length > 1 && (
            <div className={styles.mob_ad_1}>
              <img
                // @ts-ignore
                src={checkForImageType(ad[1].couponImage)}
                alt=""
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "auto",
                }}
              />
              <h4
                style={{
                  fontSize: "1.2rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[1].title}
              </h4>
              <p
                style={{
                  fontSize: "1rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[1].brand}
              </p>
              {/* @ts-ignore */}
              <a href={ad[1]?.clickUrl}></a>
            </div>
          )}
          {ad.length > 2 && (
            <div className={styles.mob_ad_1}>
              <img
                // @ts-ignore
                src={checkForImageType(ad[2].couponImage)}
                alt=""
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "auto",
                }}
              />
              <h4
                style={{
                  fontSize: "1.2rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[2].title} mob
              </h4>
              <p
                style={{
                  fontSize: "1rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[2].brand}
              </p>
              {/* @ts-ignore */}
              <a href={ad[2]?.clickUrl}></a>
            </div>
          )}
          {ad.length > 3 && (
            <div className={styles.mob_ad_1}>
              <img
                // @ts-ignore
                src={checkForImageType(ad[3].couponImage)}
                alt=""
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "auto",
                }}
              />
              <h4
                style={{
                  fontSize: "1.2rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[3].title} mob
              </h4>
              <p
                style={{
                  fontSize: "1rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[3].brand}
              </p>
              {/* @ts-ignore */}
              <a href={ad[3]?.clickUrl}></a>
            </div>
          )}
          {ad.length > 4 && (
            <div className={styles.mob_ad_1}>
              <img
                // @ts-ignore
                src={checkForImageType(ad[4].couponImage)}
                alt=""
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "auto",
                }}
              />
              <h4
                style={{
                  fontSize: "1.2rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[4].title} mob
              </h4>
              <p
                style={{
                  fontSize: "1rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[4].brand}
              </p>
              {/* @ts-ignore */}
              <a href={ad[4]?.clickUrl}></a>
            </div>
          )}
          {ad.length > 5 && (
            <div className={styles.mob_ad_1}>
              <img
                // @ts-ignore
                src={checkForImageType(ad[5].couponImage)}
                alt=""
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "auto",
                }}
              />
              <h4
                style={{
                  fontSize: "1.2rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[5].title} mob
              </h4>
              <p
                style={{
                  fontSize: "1rem",
                  textDecoration: "none !important",
                  color: "black",
                }}
              >
                {/* @ts-ignore */}
                {ad[5].brand}
              </p>
              {/* @ts-ignore */}
              <a href={ad[5]?.clickUrl}></a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BlogDetails;
