import { ScrollRestoration } from "react-router-dom";
import styles from "../../Styles/aboutUs.module.css";

const AboutUs = () => {
  return (
    <>
      <ScrollRestoration />
      <div className={styles.main}>
        <div className={styles.hero}>About Us</div>
        <div className={styles.content}>
          <div className={styles.page}>
            Welcome to The Pets Feed, a subsidiary of The Digital Media Feed,
            where we are dedicated to providing pet parents with the resources
            and services, they need to improve the quality of life for their
            furry friends. Our global network serves as a one-stop information
            portal for all your pet-related needs. <br />
            <br />
            Our objective at The Pets Feed is to assist you in solving your pet
            problems by providing sensible advice that is supported by our own
            knowledge and experiences. We understand that being a pet parent can
            be challenging at times, which is why we are committed to providing
            you with the information you need to make informed decisions about
            your pet's health, nutrition, and overall well-being.
            <br />
            <br />
            Our platform offers a wide range of services designed to make your
            life as a pet parent easier. From pet grooming and boarding to pet
            insurance and veterinary care, we've got you covered. Our team of
            experts is dedicated to providing you with the best possible
            services, ensuring that your pet receives the care and attention
            they deserve.
            <br />
            <br /> At The Pets Feed, we believe that pets are an important part
            of our lives, and we are committed to promoting responsible pet
            ownership. We provide information on pet adoption, training, and
            socialization, helping you to create a happy and healthy environment
            for your pet.
            <br />
            <br />
            Our community is designed to be interactive, allowing you to connect
            with other pet parents and share your own experiences. We believe
            that sharing knowledge and experiences is the best way to improve
            the lives of our pets, and we encourage you to join our community
            and become a part of the conversation.
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
