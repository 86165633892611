import React, { Component } from "react";
import styles from "../../Styles/hero.module.css";
import mouseStyle from "../../Styles/scrollMouse.module.css";
import checkForImageType from "../../utils/checkForImageType";

export default class Hero extends Component<any, any> {
  render() {
    console.log(this.props.data);
    return (
      <div
        style={
          !!this.props?.data.images
            ? {
                backgroundImage: `url('${checkForImageType(
                  this.props.data.images[0]
                )}')`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                position: "relative",
                ...this.props.style,
              }
            : { background: "#f7d08a", ...this.props.style }
        }
      >
        <div
          className={styles.container}
          style={
            !!this.props?.data.images
              ? {
                  background: "rgba(15,15,15,.4)",
                  color: "white",
                  ...this.props.style,
                }
              : { ...this.props.style }
          }
        >
          <h1>{this.props.data.title}</h1>
        </div>
        {/* <div className={mouseStyle.container}>
          <div className={mouseStyle.field}>
            <div className={mouseStyle.mouse}></div>
          </div>
        </div> */}
      </div>
    );
  }
}
