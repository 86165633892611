import React, { Component } from "react";
import AppLayout from "../../AppLayout";
import Hero from "../../Components/common/hero";
import Slider from "../../Components/common/slider";
import styles from "../../Styles/homepage.module.css";
import Homehero from "../../Components/common/Homehero";
import GreenSection from "../../Components/common/greensection";
import axios from "axios";
import { ScrollRestoration } from "react-router-dom";
import withRouter from "../../utils/withRouter";
import { sortIt } from "../../utils/utils";

class Homepage extends Component<any, any> {
  state = {
    pets: null,
    trending: [],
    featured: [],
    all: [],
    first_section: null,
    second_section: null,
    hero: null,
    ads: [],
  };

  componentDidMount(): void {
    axios({
      method: "get",
      url: "https://backend.theholidayfeed.com/api/pets/",
    })
      .then((response) => {
        let data = sortIt(response.data.pets);

        let final: any = {
          hero: null,
          first_section: null,
          second_section: null,
          featured: [],
          trending: [],
        };

        this.setState({ all: data });

        for (let i = 0; i < data.length; i++) {
          if (data[i].blogCategory === "hero") {
            final.hero = data[i];
            break;
          }
        }

        for (let i = 0; i < data.length; i++) {
          if (data[i].blogCategory === "discover-1") {
            final.first_section = data[i];
            break;
          }
        }

        for (let i = 0; i < data.length; i++) {
          if (data[i].blogCategory === "discover-2") {
            final.second_section = data[i];
            break;
          }
        }

        for (let i = 0; i < data.length; i++) {
          if (data[i].blogType === "featured") {
            final.featured = [...final.featured, data[i]];
          }

          if (data[i].blogType === "trending") {
            final.trending = [...final.trending, data[i]];
          }
        }

        this.setState(final);
        this.getAds();
      })
      .catch((error) => {});
  }

  getAds = () => {
    axios({
      method: "get",
      url: "https://backend.theholidayfeed.com/api/advertisements?identifier=thepetsfeed.com",
    })
      .then((res) => {
        this.setState({
          ads: res.data.advertisements
            .filter((val: any) => val.onHome)
            .sort((a: any, b: any) => a.priority - b.priority),
        });
      })
      .catch((err) => {
        console.log("something went wrong : ", err);
      });
  };

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (this.props.router.location.hash === "#trending") {
      document
        .getElementById("trending")
        ?.scrollIntoView({ behavior: "smooth" });
    } else if (this.props.router.location.hash === "#featured") {
      document
        .getElementById("featured")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    return (
      <>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "20px",
            width: "100vw",
          }}
        >
          {this.state.hero && <Homehero data={this.state.hero} />}

          <Slider
            style={{ padding: "0 0 50px 0" }}
            textStyle={{
              fontWeight: 400,
              letterSpacing: "3px",
            }}
            heading="TRENDING"
            id="trending"
            data={sortIt(this.state.trending)}
            showBtn={true}
            btnTxt="View All"
            onBtnClick={() => {
              this.props.router.navigate("/trending");
            }}
          />

          {this.state.first_section && (
            <GreenSection
              imageleft={true}
              data={this.state.first_section}
              ads_data={this.state.ads}
            />
          )}
          <Slider
            style={{ padding: "0 0 50px 0" }}
            textStyle={{
              fontWeight: 400,
              letterSpacing: "3px",
            }}
            heading="FEATURED"
            id="featured"
            data={sortIt(this.state.featured)}
            showBtn={true}
            btnTxt="View All"
            onBtnClick={() => {
              this.props.router.navigate("/featured");
            }}
          />
          {this.state.second_section && (
            <GreenSection
              imageleft={false}
              data={this.state.second_section}
              ads_data={this.state.ads}
            />
          )}
          <Slider
            style={{ paddingBottom: "0px" }}
            textStyle={{
              fontWeight: 400,
              letterSpacing: "3px",
            }}
            heading="LATEST"
            data={sortIt(this.state.all).slice(0, 8)}
            showBtn={true}
            btnTxt="View all"
            onBtnClick={() => this.props.router.navigate("/latest")}
          />
        </div>
      </>
    );
  }
}

export default withRouter(Homepage);
