import styles from "../../Styles/appLayout.module.css";
import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../Assets/logo/logo.svg";
import { ReactComponent as LogoText } from "../../Assets/logo/logo_text.svg";
import { ReactComponent as Search } from "../../Assets/icon/search-svgrepo-com.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

const Nav = () => {
  const location = useLocation().pathname.split("/")[1];
  const [menu, setMenu] = useState(false);
  const [focus, setFocus] = useState(false);
  const [search, setSearch] = useState("");
  const [mobModal, setMobModal] = useState(false);
  const [onResults, setOnResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [other, setOther] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(search);
      if (search) {
        setLoading(true);
      }

      if ((search && focus) || (search && mobModal)) {
        axios({
          method: "get",
          url: `https://backend.theholidayfeed.com/api/pets/?keyword=${search}`,
        })
          .then((res) => {
            setSearchResult(
              res.data.pets.filter((val: any) => {
                console.log("searching for : ", search);
                console.log("searching in : ", val.title);
                return val.title.toLowerCase().includes(search.toLowerCase());
              })
            );
            setLoading(false);
          })
          .catch((err) => {
            console.log(err.response.data.message);
            setSearchResult([]);
            setLoading(false);
          });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (menu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [menu]);

  useEffect(() => {
    if (mobModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobModal]);

  useEffect(() => {
    if (other) {
      document.addEventListener("click", (e: any) => {
        if (typeof e.target.className === "string") {
          if (
            // @ts-ignore
            e.target.className?.includes("other_dropdown") ||
            // @ts-ignore
            e.target.className?.includes("dropdown_list")
          ) {
          } else {
            setOther(false);
          }
        }
      });
    }
  }, []);

  return (
    <>
      <div
        className={styles.nav_container}
        style={menu ? { background: "white" } : {}}
      >
        {/* send logo here to attach here */}
        <div
          className={styles.logoo}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        >
          <Logo style={{ height: "30px" }} />
          <LogoText style={{ height: "30px" }} />
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <ul className={styles.right_nav_elements}>
          <li onClick={() => navigate("/")}>Home</li>
          <li
            onClick={() => {
              navigate("/cats");
            }}
          >
            Cats
          </li>
          <li
            onClick={() => {
              navigate("/dogs");
            }}
          >
            Dogs
          </li>
          <li
            onClick={() => {
              navigate("/fish");
            }}
          >
            Fish
          </li>
          <li
            className={styles.other_dropdown}
            onClick={() => setOther((val) => !val)}
          >
            Other
            {other && (
              <ul className={styles.dropdown_list}>
                <li
                  onClick={() => {
                    navigate("/animal/horse");
                  }}
                >
                  Horse
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/rabit");
                  }}
                >
                  Rabbit
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/hamster");
                  }}
                >
                  Hamster
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/cows");
                  }}
                >
                  Cows
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/sheeps");
                  }}
                >
                  Sheep
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/goat");
                  }}
                >
                  Goat
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/birds");
                  }}
                >
                  Birds
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/guinea_pig");
                  }}
                >
                  Guinea Pigs
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/gerbils");
                  }}
                >
                  Gerbils
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/ferrets");
                  }}
                >
                  Ferrets
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/hedgehogs");
                  }}
                >
                  Hedgehogs
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/pet_rats");
                  }}
                >
                  Pet Rats
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/turtle");
                  }}
                >
                  Turtle
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/snake");
                  }}
                >
                  Snake
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/geckos");
                  }}
                >
                  Geckos
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/frogs");
                  }}
                >
                  Frogs
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/bearded_dragons");
                  }}
                >
                  Bearded Dragons
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/monkey");
                  }}
                >
                  Monkey
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/mule");
                  }}
                >
                  Mule
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/tortoise");
                  }}
                >
                  Tortoise
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/other");
                  }}
                >
                  Other
                </li>
              </ul>
            )}
          </li>
        </ul>

        <div className={styles.search_container_main}>
          <div className={styles.search_container}>
            <input
              type="text"
              placeholder="Search the pets feed"
              onFocus={() => setFocus(true)}
              onBlur={() => {
                if (!onResults) {
                  setFocus(false);
                }
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Search />
            {focus && search && (
              <div className={styles.search_results_container}>
                {loading ? (
                  <div
                    style={{
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className="loader_search"></span>
                  </div>
                ) : searchResult.length === 0 ? (
                  <div
                    style={{
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1rem",
                        textDecoration: "none !important",
                        color: "rgba(0,0,0,.5)",
                      }}
                    >
                      No blog found
                    </p>
                  </div>
                ) : (
                  <ul
                    onMouseEnter={() => setOnResults(true)}
                    onMouseLeave={() => setOnResults(false)}
                  >
                    {searchResult.map((val: any, index: any) => {
                      return (
                        <li
                          key={index + "_nav_search"}
                          onClick={() => {
                            navigate(`/blog/${val._id}`);
                            setFocus(false);
                            setOnResults(false);
                          }}
                        >
                          {val.title}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        {!mobModal && (
          <Search
            className={styles.mob_search_icon}
            onClick={() => setMobModal((val) => !val)}
          />
        )}
        <div
          className={styles.menu_btn}
          onClick={() => {
            setMenu((val) => !val);
          }}
        >
          <div className={`${styles.one} ${menu ? styles.one_move : {}}`}></div>
          <div
            className={`${styles.two}  ${menu ? styles.two_move : {}}`}
          ></div>
          <div
            className={`${styles.three} ${menu ? styles.three_move : {}}`}
          ></div>
        </div>
      </div>
      {menu && (
        <div
          className={`${styles.small_menu} ${
            menu ? styles.small_menu_open : {}
          }`}
        >
          {menu && (
            <div
              className={styles.small_menu_content}
              style={{ overflow: "auto" }}
            >
              <ul className={styles.right_nav_elements_mob}>
                <li
                  onClick={() => {
                    document
                      .getElementById("trending")
                      ?.scrollIntoView({ behavior: "smooth" });
                    setMenu(false);
                  }}
                >
                  Trending
                </li>
                <li
                  onClick={() => {
                    document
                      .getElementById("featured")
                      ?.scrollIntoView({ behavior: "smooth" });
                    setMenu(false);
                  }}
                >
                  Featured
                </li>
                <li
                  onClick={() => {
                    navigate("/latest");
                    setMenu(false);
                  }}
                >
                  Latest
                </li>
                <li
                  onClick={() => {
                    navigate("/cats");
                    setMenu(false);
                  }}
                >
                  Cats
                </li>
                <li
                  onClick={() => {
                    navigate("/dogs");
                    setMenu(false);
                  }}
                >
                  Dogs
                </li>
                <li
                  onClick={() => {
                    navigate("/fish");
                    setMenu(false);
                  }}
                >
                  Fish
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/horse");
                    setMenu(false);
                  }}
                >
                  Horse
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/rabit");
                    setMenu(false);
                  }}
                >
                  Rabbit
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/hamster");
                    setMenu(false);
                  }}
                >
                  Hamster
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/cows");
                    setMenu(false);
                  }}
                >
                  Cows
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/sheeps");
                    setMenu(false);
                  }}
                >
                  Sheep
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/goat");
                    setMenu(false);
                  }}
                >
                  Goat
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/birds");
                    setMenu(false);
                  }}
                >
                  Birds
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/guinea_pig");
                    setMenu(false);
                  }}
                >
                  Guinea Pigs
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/gerbils");
                    setMenu(false);
                  }}
                >
                  Gerbils
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/ferrets");
                    setMenu(false);
                  }}
                >
                  Ferrets
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/hedgehogs");
                    setMenu(false);
                  }}
                >
                  Hedgehogs
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/pet_rats");
                    setMenu(false);
                  }}
                >
                  Pet Rats
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/turtle");
                    setMenu(false);
                  }}
                >
                  Turtle
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/snake");
                    setMenu(false);
                  }}
                >
                  Snake
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/geckos");
                    setMenu(false);
                  }}
                >
                  Geckos
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/frogs");
                    setMenu(false);
                  }}
                >
                  Frogs
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/bearded_dragons");
                    setMenu(false);
                  }}
                >
                  Bearded Dragons
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/monkey");
                    setMenu(false);
                  }}
                >
                  Monkey
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/mule");
                    setMenu(false);
                  }}
                >
                  Mule
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/tortoise");
                    setMenu(false);
                  }}
                >
                  Tortoise
                </li>
                <li
                  onClick={() => {
                    navigate("/animal/other");
                    setMenu(false);
                  }}
                >
                  Other
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
      <div className={`${styles.menu} ${mobModal ? styles.menu_open : {}}`}>
        <div className={styles.mob_modal}>
          <div className={styles.mob_modal_input}>
            <input
              type="text"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            <Search />
          </div>

          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <span className="loader_search"></span>
            </div>
          ) : searchResult.length === 0 ? (
            <div
              style={{
                flexGrow: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "1rem",
                  textDecoration: "none !important",
                  color: "rgba(0,0,0,.5)",
                }}
              >
                No Result Found
              </span>
            </div>
          ) : (
            <ul style={{ flexGrow: 1 }}>
              {searchResult.map((val: any, index: any) => {
                return (
                  <li
                    key={index + "_nav_search_mob"}
                    onClick={() => {
                      navigate(`/blog/${val._id}`);
                      setFocus(false);
                      setOnResults(false);
                      setMobModal(false);
                    }}
                    style={{ fontSize: "16px" }}
                  >
                    {val.title}
                  </li>
                );
              })}
            </ul>
          )}
          <p
            style={{
              textAlign: "center",
              fontWeight: 600,
              textDecoration: "underline",
              marginBottom: "60px",

              height: "30px",
              padding: "10px 0",
            }}
            onClick={() => setMobModal(false)}
          >
            Close
          </p>
        </div>
      </div>
    </>
  );
};

export default Nav;
