const checkForImageType = (file: string)=>{

    

    if(file !== undefined){
    let type = file.split('.')[file.split('.').length - 1]

    console.log("File is : ", file)

    if(type === "gif"){
        return process.env.REACT_APP_IMAGE_LINK + file
    }

    return process.env.REACT_APP_IMAGE_BASE_LINK + file
    }

    return file

}

export default checkForImageType;